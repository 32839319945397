//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DarkBg from '@/components/dark-bg.vue';
import PageTitle from '@/components/page-title.vue';
export default {
  components: {
    DarkBg: DarkBg,
    PageTitle: PageTitle
  },
  data: function data() {
    var showBack = this.$route.query.showBack;
    var iframeUrl = process.env.VUE_APP_TARGET === 'online' ? "https://".concat(location.host, "/pubstatic/agreements/agreement_user_register.html") : 'https://th5.xdfzxb.com/pubstatic/agreements/agreement_user_register.html';
    return {
      showBack: showBack,
      iframeUrl: iframeUrl
    };
  },
  methods: {
    goLoginView: function goLoginView() {
      window.history.back();
    }
  }
};