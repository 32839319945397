/*
 * @Author       : lvquanqiang@xdf.cn
 * @Date         : 2021-10-26 16:13:21
 * @LastEditors  : zhaixiaowei@xdf.cn
 * @LastEditTime : 2022-05-22 22:44:50
 * @Description  : 学习页接口集
 */
import { request } from '../request'; // 获取科目列表

export var getSubjectList = function getSubjectList(params) {
  return request.post("/study/subject", params);
}; // 根据科目获取自学宝列表

export var getBoxList = function getBoxList(params) {
  return request.get("/study/box_list", params);
}; // 获取自学宝大纲目录

export var getBoxOutline = function getBoxOutline(params) {
  return request.get("/study/box_outline/v1", params);
}; // 学习-学习宝目录节点服务获取

export var getOutlineContent = function getOutlineContent(params) {
  return request.post("/study/box/outline/content", params);
}; // 获取用户专属服务项信息

export var getExclusiveService = function getExclusiveService(params) {
  return request.post("/study/exclusiveService", params);
}; // 提交获取页签-查询学习宝\练习宝题组tab标签

export var getItemGroupTabs = function getItemGroupTabs(params) {
  return request.post('/study/box/itemgroup/tabs', params);
}; // 获取已提交练习记录页面url

export var getExerciseRecordUrl = function getExerciseRecordUrl(params) {
  return request.post('/resource/guide/query', params);
}; // 获取策略

export var getStrategy = function getStrategy(params) {
  return request.post('/study/strategy', params);
}; // 获取大纲的服务项和学习任务

export var getOutlineContentTask = function getOutlineContentTask(params) {
  return request.post('/study/box/outline/content/v1', params);
}; // 学习页服务项标题栏锁状态及掌握程度及掌握程度对应的推荐策略

export var getServiceTagInfo = function getServiceTagInfo(params) {
  return request.post('/study/service/tag/info', params);
}; // 获取关或者章的书本学习进度

export var getOutLineProgress = function getOutLineProgress(params) {
  return request.post('/study/outline/progress', params);
}; // 查询方法盒子勘误信息

export var getErrataList = function getErrataList(params) {
  return request.post('/study/box/errata/list', params);
};