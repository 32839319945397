//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ServiceDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogVisible: this.showDialog,
      dialogTitle: '定制服务介绍',
      source: 'https://ucanvod.xdf.cn/ff9e0ddevodtransbj1400317282/df4c4202387702299294418747/v.f100830.mp4?t=7c14ad82&sign=e7666f9e6db5edb20d4c60eb959f4b25',
      poster: 'https://ucanos.xdf.cn/troy/pro/files/help/document/2022/03/17/cover0004-1.png'
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('update:showDialog', false);
    }
  }
};