//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BoxSubmitState',
  props: {
    states: {
      type: Array,
      default: null
    }
  },
  methods: {
    handleClick: function handleClick(item) {
      if (!item.select) {
        this.$emit('changeStatus', item);
      }
    }
  }
};