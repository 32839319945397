//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    tipList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$attrs.close();
    }
  }
};