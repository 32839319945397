//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import IETip from '@/components/ie-tip.vue';
import NavBar from './nav';
import MainSection from './main';
export default {
  name: 'Layout',
  components: {
    IETip: IETip,
    NavBar: NavBar,
    MainSection: MainSection
  }
};