//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EntryBtn',
  methods: {
    handlerExpandDrawer: function handlerExpandDrawer() {
      this.$store.dispatch('setExpandExperience', true);
    }
  }
};