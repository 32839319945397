import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TextbookList from '@/components/textbook-list.vue';
export default {
  components: {
    TextbookList: TextbookList
  },
  props: {
    hiddenSubject: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      selectTextbook: false,
      preciousName: ''
    };
  },
  mounted: function mounted() {// window.addEventListener('message', (ev) => { // 监听iframe里面的click事件，把自学宝的列表弹框关掉
    //   if (ev.data.eventName === 'documentClick') {
    //     this.changePreciousListStatus(false)
    //   }
    // })
  },
  methods: {
    setPreciousName: function setPreciousName(name) {
      // 弹框点击确定关闭后把最新的名字传出来
      this.preciousName = name.length > 15 ? "".concat(name.slice(0, 15), "...") : name;
    },
    changePreciousListStatus: function changePreciousListStatus() {
      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.selectTextbook = typeof status === 'boolean' ? status : false;
    }
  }
};