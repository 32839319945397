import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _Drawer2 from "element-ui/lib/theme-chalk/drawer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Drawer from "element-ui/lib/drawer";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Empty2 from "element-ui/lib/theme-chalk/empty.css";
import "element-ui/lib/theme-chalk/base.css";
import _Empty from "element-ui/lib/empty";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _Radio2 from "element-ui/lib/theme-chalk/radio.css";
import "element-ui/lib/theme-chalk/base.css";
import _Radio from "element-ui/lib/radio";
import _Tree2 from "element-ui/lib/theme-chalk/tree.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tree from "element-ui/lib/tree";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _OptionGroup2 from "element-ui/lib/theme-chalk/option-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _OptionGroup from "element-ui/lib/option-group";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _Scrollbar2 from "element-ui/lib/theme-chalk/scrollbar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Scrollbar from "element-ui/lib/scrollbar";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";

/**
 * Element 按需加载
 * 可加载组件列表 https://github.com/ElemeFE/element/blob/master/components.json
 */
import Vue from 'vue';
Vue.use(_Button);
Vue.use(_Card);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_DatePicker);
Vue.use(_Scrollbar);
Vue.use(_Input);
Vue.use(_Select);
Vue.use(_Option);
Vue.use(_OptionGroup);
Vue.use(_Tabs);
Vue.use(_TabPane);
Vue.use(_Tree);
Vue.use(_Radio);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_Cascader);
Vue.use(_Empty);
Vue.use(_Dialog);
Vue.use(_Tooltip);
Vue.use(_Pagination);
Vue.use(_Drawer); // Vue.use(Message)
// 会触发进入页面蹦出空的toast提示 https://github.com/elemefe/element/issues/5243

Vue.prototype.$message = _Message;
Vue.prototype.$messageBox = _MessageBox;
Vue.prototype.$confirm = _MessageBox.confirm;