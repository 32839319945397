import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Login from './components/login.vue';
import Filing from '@/components/filing.vue';
import PerfectInfo from './components/perfect-info.vue';
import IETip from '@/components/ie-tip.vue';
import { buryWithoutLogin } from '@/api/modules/bury';
export default {
  components: {
    Login: Login,
    PerfectInfo: PerfectInfo,
    Filing: Filing,
    IETip: IETip
  },
  data: function data() {
    // 退出体验模式时
    var isPerfectInfo = sessionStorage.getItem('isPerfectInfo') || false;
    return {
      isPerfectInfo: isPerfectInfo,
      isFinishLogin: isPerfectInfo,
      noFinishInfo: {}
    };
  },
  watch: {
    // 必须根据是否需要完善信息初始化noFinishInfo数据，否则路由守卫会报错
    isPerfectInfo: {
      immediate: true,
      handler: function handler(val) {
        if (val) {
          var user = JSON.parse(localStorage.getItem('userInfo'));
          this.finishLogin(user);
        }
      }
    }
  },
  mounted: function mounted() {
    var message = localStorage.getItem('webLoginMessage');

    if (message) {
      _Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      });

      localStorage.removeItem('webLoginMessage');
    } // 埋点


    this.bury();
  },
  methods: {
    bury: function bury() {
      buryWithoutLogin({
        action: '登陆页面初始化',
        desc: '登陆页面初始化'
      });
    },
    finishLogin: function finishLogin(val) {
      this.noFinishInfo = val;
      this.isFinishLogin = true;
      this.$store.dispatch('setUserMode', 'normal');
    },
    dealVideoDom: function dealVideoDom() {
      var myVideo = document.createElement('video');
      myVideo.src = this.$resource('login-background-video.mp4', 'video');
      myVideo.autoplay = true;
      myVideo.loop = true;
      myVideo.muted = true;
      var style = {
        position: 'fixed',
        right: '0px',
        top: '0px',
        height: '100%',
        width: '100%',
        objectFit: 'fill'
      };

      for (var key in style) {
        myVideo.style[key] = style[key];
      }

      myVideo.oncanplaythrough = function () {
        var container = document.getElementsByClassName('login-main')[0];
        var picDom = document.getElementsByClassName('login-background-pic')[0];
        container.insertBefore(myVideo, picDom);
        myVideo.className = 'myVideo';
        picDom.style.display = 'none';
        myVideo.oncanplaythrough = null;
      };
    }
  }
};