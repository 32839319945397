import Vue from 'vue';
import Vuex from 'vuex';
import localUser from './modules/localUser';
import home from './modules/home';
import getters from './getters';
Vue.use(Vuex);
var modules = {
  localUser: localUser,
  home: home
};
var state = {};
var mutations = {};
var actions = {};
export default new Vuex.Store({
  // 严格模式，只能通过mutations来修改状态.
  strict: process.env.NODE_ENV !== 'production',
  modules: modules,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
});