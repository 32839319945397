//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DarkBg from '@/components/dark-bg.vue';
import callService from '@/utils/call-service'; // 服务提示

var SERVICE_TIP_MAP = {
  1: '针对性解决你在做题过程中暴露的薄弱知识点',
  2: '根据你的错题生成相似类型题目，助你更好掌握<br/>所学知识，快去练习吧',
  3: '你已经掌握的知识点，可以进行拔高训练',
  6: '记录你所有做错的题目，在错题本中可以查看题<br/>目解析，并对错题进行重练'
};
export default {
  name: 'HomeServices',
  components: {
    DarkBg: DarkBg
  },
  props: {
    serviceList: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      tipMap: SERVICE_TIP_MAP
    };
  },
  methods: {
    routerChange: function routerChange() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      callService(params.url, this);
    }
  }
};