//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DownLoadApp',
  data: function data() {
    return {
      erCode: this.$resource('app-download-ercode.png')
    };
  },
  methods: {}
};