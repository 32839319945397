import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";

/*
 * @Author       : lvquanqiang@xdf.cn
 * @Date         : 2022-03-08 11:02:20
 * @LastEditors  : fengxiaoyu4@xdf.cn
 * @LastEditTime : 2022-09-26 12:42:42
 * @Description  : 视频播放
 */
var env = process.env.VUE_APP_TARGET; // 引导视频的baseUrl

export var BASE_URL_MAP = {
  online: "https://".concat(location.host, "/tcscreen"),
  pre: "https://".concat(location.host, "/tcscreen"),
  test: "https://".concat(location.host, "/tcscreen/").concat(env)
}; // 播放视频baseUrl

export function getVideoBaseUrl() {
  return BASE_URL_MAP[env] || BASE_URL_MAP['test'];
} // 获取视频播放url

export function getVideoUrl(video) {
  var videoId = video.videoId,
      videoType = video.videoType,
      videoName = video.videoName;
  return "".concat(getVideoBaseUrl(), "/answer/?pageType=5&videoId=").concat(videoId, "&videoType=").concat(videoType, "&videoName=").concat(videoName, "&showWebFullScreenBtn=1");
} // 视频暂停播放message
// 体验模式激活的时候要暂停智能学习系统的视频的播放｜继续播放

export function playOrPauseVideo(eventName) {
  var serviceIframe = document.getElementById('serviceIframe');
  var iframeWin = serviceIframe ? serviceIframe.contentWindow : null;

  if (iframeWin) {
    iframeWin.postMessage({
      eventName: eventName
    }, '*');
  }
}