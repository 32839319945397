import _createForOfIteratorHelper from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";

/**
 * @Author       : lvquanqiang@xdf.cn
 * @Date         : 2021-10-26 10:59:22
 * @LastEditors  : lvquanqiang@xdf.cn
 * @LastEditTime : 2022-05-19 10:26:01
 * @Description  : 学习页的常量文件
 */
import { resource } from '@/utils/tools'; // 大纲类型

export var OUTLINE_TYPE = {
  1: '章',
  2: '关',
  3: '关卡',
  4: '本章服务',
  5: '本章练习',
  6: '本关服务',
  7: '本关练习'
}; // 虚拟节点类型 - 映射转为真实节点

export var VIRTUAL_NODE_TYPE = {
  2: {
    name: '本章服务',
    type: 4
  },
  4: {
    name: '本章练习：',
    type: 5
  },
  1: {
    name: '本关服务',
    type: 6
  },
  3: {
    name: '本关练习：',
    type: 7
  }
}; // 提交状态 1 未全部提交 2 全部提交

export var SUBMIT_STATUS = [{
  status: 0,
  name: '全部',
  select: true
}, {
  status: 1,
  name: '未全部提交',
  select: false
}, {
  status: 2,
  name: '全部提交',
  select: false
}]; // 宝类型

export var BAO_TYPE = {
  1: {
    name: '学习分册',
    icon: resource('img-guide-study.png')
  },
  2: {
    name: '练习分册',
    icon: resource('img-guide-practice.png')
  }
}; // 专属服务项

export var SERVICE_MAP = {
  1: {
    // '薄弱知识点',
    icon: resource('ucan_img_study_icon_weak.png'),
    textImg: resource('ucan_img_study_bg_leak.png'),
    style: {
      width: '378px',
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_weak2.png'), ")")
    },
    imgStyle: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_weak.png'), ")")
    }
  },
  2: {
    // '已掌握知识点难度提升练习',
    icon: resource('ucan_img_study_icon_master.png'),
    textImg: resource('ucan_img_study_bg_ability.png'),
    style: {
      width: '378px',
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_master2.png'), ")")
    },
    imgStyle: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_master.png'), ")")
    }
  },
  3: {
    // '错题相似题',
    icon: resource('ucan_img_study_icon_like.png'),
    textImg: resource('ucan_img_study_bg_inference.png'),
    style: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_like2.png'), ")")
    },
    imgStyle: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_like.png'), ")")
    }
  },
  4: {
    // '错题本',
    icon: resource('ucan_img_study_icon_error.png'),
    textImg: resource('ucan_img_study_bg_basics.png'),
    style: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_error.png'), ")")
    }
  },
  5: {
    // '方法盒子报告',
    icon: resource('ucan_img_study_icon_detail.png'),
    textImg: resource('ucan_img_study_bg_stage.png'),
    style: {
      backgroundImage: "url(".concat(resource('ucan_img_study_bg_report.png'), ")")
    }
  }
}; // 服务项状态

export var SERVICE_STATUS_MAP = [{
  lockStatus: 1,
  title: 'Hello！定制服务还未解锁',
  subTitle: '提交「学习分册」「练习分册」题目即可解锁定制服务， “AI智能”  为你生成线上定制练习，高效掌握知识点',
  statusImg: resource('service_unLock.png')
}, {
  lockStatus: 2,
  title: '提交全部练习可获取更精准的定制服务',
  subTitle: '提交「学习分册」「练习分册」全部练习， “AI智能”  生成的线上定制练习更精准',
  statusImg: resource('service_unLock.png')
}, {
  lockStatus: 3,
  title: '你真棒！已提交全部练习题作答',
  subTitle: '已为你解锁全部定制服务，通过以下定制服务练习进一步提升知识点掌握程度。',
  statusImg: resource('service_finish.png')
}]; // 过滤节点

export var filterOutlineNodes = function filterOutlineNodes(states, nodes, context) {
  if (states.some(function (state) {
    return state.select && state.status === 0;
  }) || states.every(function (st) {
    return st.select;
  }) || states.every(function (st) {
    return !st.select;
  })) {
    var _iterator = _createForOfIteratorHelper(nodes),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var node = _step.value;
        context.$set(node, 'hideNode', false);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  } else {
    // 1未全部提交2 全部提交
    var _states$find = states.find(function (s) {
      return s.select;
    }),
        status = _states$find.status;

    var _iterator2 = _createForOfIteratorHelper(nodes),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _node = _step2.value;

        if (_node.status === 2) {
          // 该章是“全部提交”，选择了“未全部”则隐藏，选择了“全部”则显示
          context.$set(_node, 'hideNode', status === 1);
        } else if (status === 1) {
          // 该条件以下，该章全是“未全部提交”状态
          // 如果选择了“未全部提交”，在该条件(未全部提交)下全部显示
          context.$set(_node, 'hideNode', false);
        } else {
          // 该条件下，章是“未全部提交”，且选择了“全部提交”
          var isHide = true; // 默认隐藏，有几个条件下不隐藏

          if (_node.virtualNode && _node.virtualNode.some(function (v) {
            return v.status === 2;
          })) {
            isHide = false; // 虚拟节点中含有“全部提交”
          } else if (_node.children && _node.children.some(function (c) {
            return c.status === 2;
          })) {
            isHide = false; // 子节点（关）含有“全部提交”
          } else if (_node.children && _node.children.length) {
            // 关内的子节点（关卡）或者虚拟节点含有“全部提交”
            if (_node.children.some(function (subnode) {
              return subnode.children && subnode.children.some(function (c) {
                return c.status === 2;
              }) || subnode.virtualNode && subnode.virtualNode.some(function (v) {
                return v.status === 2;
              });
            })) {
              isHide = false;
            }
          }

          context.$set(_node, 'hideNode', isHide);
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
};