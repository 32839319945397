import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BoxMainOutlineCardTag from '@/views/study/components/box-main-outline-card-tag.vue';
import { VIRTUAL_NODE_TYPE } from '@/views/study/constance.js';
export default {
  name: 'BoxMainOutlineCard',
  components: {
    BoxMainOutlineCardTag: BoxMainOutlineCardTag
  },
  props: {
    selectNode: {
      type: Object,
      default: function _default() {}
    },
    sectionName: {
      // 关/章名称，用来拼接parentName
      type: String,
      default: ''
    },
    virtualNode: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    states: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      scrollStyle: ''
    };
  },
  computed: {
    activeNode: function activeNode() {
      if (!this.selectNode) {
        return false;
      }

      var _this$virtualNode = this.virtualNode,
          virtualId = _this$virtualNode.virtualId,
          type = _this$virtualNode.type,
          outLineId = _this$virtualNode.outLineId;
      var _this$selectNode = this.selectNode,
          seOutLineId = _this$selectNode.outLineId,
          seVirtualId = _this$selectNode.virtualId,
          seType = _this$selectNode.type;
      return "".concat(virtualId, "_").concat(type, "_").concat(outLineId) === "".concat(seVirtualId, "_").concat(seType, "_").concat(seOutLineId);
    },
    nodeSeqName: function nodeSeqName() {
      var _this$virtualNode2 = this.virtualNode,
          outLineType = _this$virtualNode2.outLineType,
          type = _this$virtualNode2.type,
          outLineSeqName = _this$virtualNode2.outLineSeqName;

      if (outLineType === 2) {
        // 关
        return "".concat(outLineSeqName, "\uFF1A");
      }

      return VIRTUAL_NODE_TYPE[type].name;
    },
    nodeName: function nodeName() {
      var _this$virtualNode3 = this.virtualNode,
          name = _this$virtualNode3.name,
          outLineType = _this$virtualNode3.outLineType,
          outLineName = _this$virtualNode3.outLineName;

      if (outLineType === 2) {
        // 关
        return outLineName;
      }

      return name || outLineName || '';
    },
    hideNode: function hideNode() {
      // 隐藏节点
      if (this.states.some(function (state) {
        return state.select && state.status === 0;
      }) || this.states.every(function (st) {
        return st.select;
      }) || this.states.every(function (st) {
        return !st.select;
      })) {
        return false;
      }

      var _this$states$find = this.states.find(function (s) {
        return s.select;
      }),
          status = _this$states$find.status;

      return status !== this.virtualNode.status;
    }
  },
  mounted: function mounted() {
    var elWidth = this.$refs.virtualNodeName.offsetWidth;

    if (elWidth > 152) {
      this.scrollStyle = {
        animationDuration: "".concat(elWidth / 200 * 5, "s")
      };
    }
  },
  methods: {
    clickNode: function clickNode() {
      this.virtualNode.parentName = this.sectionName;
      this.$emit('nodeClick', this.virtualNode);
    }
  }
};