import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.json.stringify.js";

var getDefaultServiceTitle = function getDefaultServiceTitle() {
  var serviceTitle = sessionStorage.getItem('serviceTitle');
  return serviceTitle ? JSON.parse(serviceTitle) : {
    title: '',
    showSubject: true
  };
};

export default {
  state: {
    unBindSubject: false,
    // 学科绑定状态
    // showIframeSubject: sessionStorage.getItem('showIframeSubject') !== 'false' // 是否显示/service页面的subject列表
    // 服务页面头部title和是否显示学科
    serviceTitle: getDefaultServiceTitle(),
    progressSelectNode: null,
    // 显示服务项页面的loading
    maskLoadingVisible: false,
    expandExperience: false,
    // 体验模式是否展开中
    experienceInfos: {},
    // preciousType: 1, pageNum: 1 跳转错题本需要记录返回的时候用
    experienceBook: JSON.parse(localStorage.getItem('experienceBook')) || {} // 体验模式书本信息

  },
  mutations: {
    SET_UNBIND_SUBJECT: function SET_UNBIND_SUBJECT(state, args) {
      state.unBindSubject = args;
    },
    SET_SERVICE_PAGE_TITLE: function SET_SERVICE_PAGE_TITLE(state, args) {
      // state.showIframeSubject = args
      // sessionStorage.setItem('showIframeSubject', args)
      state.serviceTitle = _objectSpread({}, args);
      sessionStorage.setItem('serviceTitle', JSON.stringify(args));
    },
    SET_PROGRESS_SELECTNODE: function SET_PROGRESS_SELECTNODE(state, args) {
      state.progressSelectNode = args;
    },
    TOGGLE_MASK_LOADING: function TOGGLE_MASK_LOADING(state, args) {
      state.maskLoadingVisible = args;
    },
    SET_EXPAND_EXPERIENCE: function SET_EXPAND_EXPERIENCE(state, args) {
      state.expandExperience = args;
    },
    // 体验模式展开中 导航栏根据该状态设置内容
    SET_EXPERIENCE_INFOS: function SET_EXPERIENCE_INFOS(state, args) {
      state.experienceInfos = args;
    },
    SET_EXPERIENCE_BOOK: function SET_EXPERIENCE_BOOK(state, args) {
      // 保存体验模式书本信息
      state.experienceBook = args;
      localStorage.setItem('experienceBook', JSON.stringify(args));
    }
  },
  actions: {
    setUnbindSubject: function setUnbindSubject(_ref, args) {
      var commit = _ref.commit;
      commit('SET_UNBIND_SUBJECT', args);
    },
    setServicePageTitle: function setServicePageTitle(_ref2, args) {
      var commit = _ref2.commit;
      commit('SET_SERVICE_PAGE_TITLE', args);
    },
    setProgressSelectNode: function setProgressSelectNode(_ref3, args) {
      var commit = _ref3.commit;
      commit('SET_PROGRESS_SELECTNODE', args);
    },
    toggleMaskLoading: function toggleMaskLoading(_ref4, args) {
      var commit = _ref4.commit;
      commit('TOGGLE_MASK_LOADING', args);
    },
    setExpandExperience: function setExpandExperience(_ref5, args) {
      var commit = _ref5.commit;
      commit('SET_EXPAND_EXPERIENCE', args);
    },
    setExperienceInfos: function setExperienceInfos(_ref6, args) {
      var commit = _ref6.commit;
      commit('SET_EXPERIENCE_INFOS', args);
    },
    setExperienceBook: function setExperienceBook(_ref7, args) {
      var commit = _ref7.commit;
      commit('SET_EXPERIENCE_BOOK', args);
    }
  }
};