//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ActiveErrorDialog',
  props: {
    message: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  methods: {
    // 关闭当前dialog
    closeDialog: function closeDialog() {
      this.$emit('toggleActiveError');
    }
  }
};