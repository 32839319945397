import { render, staticRenderFns } from "./box-main-outline-collapse.vue?vue&type=template&id=51292126&scoped=true&"
import script from "./box-main-outline-collapse.vue?vue&type=script&lang=js&"
export * from "./box-main-outline-collapse.vue?vue&type=script&lang=js&"
import style0 from "./box-main-outline-collapse.vue?vue&type=style&index=0&id=51292126&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51292126",
  null
  
)

export default component.exports