/*
 * @Author       : fengxiaoyu4@xdf.cn
 * @Date         : 2022-02-25 14:31:18
 * @LastEditors  : fengxiaoyu4@xdf.cn
 * @LastEditTime : 2022-02-25 14:32:34
 * @Description  : 问卷
 */
import { request } from '../request'; // 识别码列表

export var getQuestionnaireList = function getQuestionnaireList(params) {
  return request.post("/resource/questionnaire/list", params);
}; // 识别码-获取url

export var getQuestionnaireUrl = function getQuestionnaireUrl(params) {
  return request.post("/resource/questionnaire/link", params);
};