import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DarkBgCopy',
  props: {
    radius: {
      required: true,
      type: String
    },
    colorOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    transparency: {
      type: Array,
      default: function _default() {
        return [0.1, 1, 0.5, 0.1];
      }
    }
  },
  computed: {
    hornBasicRange: function hornBasicRange() {
      return {
        width: this.radius,
        height: this.radius
      };
    },
    colors: function colors() {
      // color0左上 color1右上 color2右下 color3左下
      return _objectSpread({
        color0: "rgba(0,204,153, ".concat(this.transparency[0], ")"),
        color1: "rgba(0, 204, 153, ".concat(this.transparency[1], ")"),
        color2: "rgba(0,204,153, ".concat(this.transparency[2], ")"),
        color3: "rgba(0,204,153, ".concat(this.transparency[3], ")")
      }, this.colorOptions);
    }
  },
  methods: {
    hornStyle: function hornStyle(index) {
      var radius = ['0', '0', '0', '0'];
      radius[index] = this.radius;
      return _objectSpread(_objectSpread({}, this.hornBasicRange), {}, {
        'border-color': this.colors["color".concat(index)],
        'border-radius': radius.join(' ')
      });
    },
    lineStyle: function lineStyle(index) {
      var styleMap = {
        0: {
          left: this.radius,
          right: this.radius,
          background: "linear-gradient(to right, ".concat(this.colors.color0, ", ").concat(this.colors.color1, ")")
        },
        1: {
          background: "linear-gradient(to top, ".concat(this.colors.color2, ", ").concat(this.colors.color1, ")"),
          top: this.radius,
          bottom: this.radius
        },
        2: {
          background: "linear-gradient(to right, ".concat(this.colors.color3, ", ").concat(this.colors.color2, ")"),
          left: this.radius,
          right: this.radius
        },
        3: {
          background: "linear-gradient(to top, ".concat(this.colors.color3, ", ").concat(this.colors.color0, ")"),
          top: this.radius,
          bottom: this.radius
        }
      };
      return styleMap[index];
    }
  }
};