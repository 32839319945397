/*
 * @Author       : zhaixiaowei@xdf.cn
 * @Date         : 2022-06-16 18:02:49
 * @LastEditors  : zhaixiaowei@xdf.cn
 * @LastEditTime : 2022-08-04 11:02:38
 * @Description  : 选择体验产品页
 */
import { request } from '../request'; // 获取年级学科列表

export var getExpSubjectList = function getExpSubjectList(params) {
  return request.get('/study/exp/list/subject', params);
}; // 根据科目获取自学宝列表

export var getExpBoxList = function getExpBoxList(params) {
  return request.post('/study/exp/list/box', params);
}; // 商品上下架状态、介绍图片数组、空介绍图、phone 模版账号

export var getProductDesc = function getProductDesc(params) {
  return request.post('/study/exp/product/desc', params);
}; // 去免费体验页 noLoading-因为要进行轮询，由页面进行loading控制，

export var accountExpStart = function accountExpStart(params) {
  return request.post('/account/exp/start', params, {
    noLoading: true
  });
}; // 轮询体验数据是否复制成功 noLoading-因为要进行轮询，由页面进行loading控制，

export var managerExpReady = function managerExpReady(params) {
  return request.get('/manager/exp/ready', params, {
    noLoading: true
  });
}; // 是否有上一次记录的体验记录

export var accountExpExist = function accountExpExist(params) {
  return request.get('/account/exp/exists', params);
};