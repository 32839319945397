//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { toLogin } from '@/utils/login';
export default {
  name: 'QuitExperience',
  data: function data() {
    return {
      visible: false
    };
  },
  methods: {
    // 去选择产品体验页
    toSelect: function toSelect() {
      this.$router.push('/select');
    },
    toggleVisible: function toggleVisible() {
      this.visible = !this.visible;
    },
    // 退出体验模式
    quit: function quit() {
      localStorage.removeItem('outline_cache');
      localStorage.removeItem('subject');
      localStorage.removeItem('preciousMessage');
      localStorage.removeItem('preciousInformation');
      toLogin();
    }
  }
};