//
//
//
//
//
//
//
//
export default {
  name: 'Main',
  computed: {
    key: function key() {
      return this.$route.path;
    }
  }
};