import _toConsumableArray from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'XDFPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    currentPage: {
      type: [Object, Number],
      default: null
    },
    preciousType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      userInput: null,
      showPrevMore: false,
      showNextMore: false,
      quicknextIconClass: 'el-icon-more',
      quickprevIconClass: 'el-icon-more'
    };
  },
  computed: {
    isPreDisabled: function isPreDisabled() {
      var _this$pageObj$cover$, _this$pageObj, _this$pageObj$cover;

      var miniPage = (_this$pageObj$cover$ = (_this$pageObj = this.pageObj) === null || _this$pageObj === void 0 ? void 0 : (_this$pageObj$cover = _this$pageObj.cover) === null || _this$pageObj$cover === void 0 ? void 0 : _this$pageObj$cover[0]) !== null && _this$pageObj$cover$ !== void 0 ? _this$pageObj$cover$ : 1;
      return this.currentPage <= miniPage;
    },
    pageArr: function pageArr() {
      return [].concat(_toConsumableArray(this.pageObj.cover), _toConsumableArray(this.pageObj.content));
    },
    // 显示更多的逻辑，boxContent对多显示3个·
    pagers: function pagers() {
      var _this$pageObj$cover$l, _this$pageObj$cover2;

      var pagerCount = 5;
      var halfPagerCount = 2;
      var currentPage = Number(this.currentPage);
      var pageCount = Number(this.total);
      var coverCount = (_this$pageObj$cover$l = (_this$pageObj$cover2 = this.pageObj.cover) === null || _this$pageObj$cover2 === void 0 ? void 0 : _this$pageObj$cover2.length) !== null && _this$pageObj$cover$l !== void 0 ? _this$pageObj$cover$l : 0;
      var showPrevMore = false;
      var showNextMore = false;

      if (pageCount + coverCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount - coverCount) {
          showPrevMore = true;
        }

        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true;
        }
      }

      var array = [];

      if (showPrevMore && !showNextMore) {
        var startPage = pageCount - (pagerCount - 2);

        for (var i = startPage; i < pageCount; i++) {
          array.push(i);
        }
      } else if (!showPrevMore && showNextMore) {
        for (var _i = 2; _i < pagerCount; _i++) {
          array.push(_i - coverCount);
        }
      } else if (showPrevMore && showNextMore) {
        var offset = Math.floor(pagerCount / 2) - 1;

        for (var _i2 = currentPage - offset; _i2 <= currentPage + offset; _i2++) {
          array.push(_i2);
        }
      } else {
        for (var _i3 = 2; _i3 < pageCount + coverCount; _i3++) {
          array.push(_i3 - coverCount);
        }
      }

      this.showPrevMore = showPrevMore;
      this.showNextMore = showNextMore;
      return array;
    },
    //
    lastPageBtn: function lastPageBtn() {
      var lastPageNum = this.pageArr[this.pageArr.length - 1];
      return lastPageNum > 0 ? lastPageNum : "\u5C01\u9762".concat(this.pageObj.cover.length);
    }
  },
  watch: {
    userInput: function userInput(val) {
      this.userInput = val === null || val === void 0 ? void 0 : val.toString().replace(/[^0-9]/g, '');
    },
    // preciousType(val) {
    //   console.log(this.currentPage)
    //   this.userInput = null
    // },
    currentPage: function currentPage(val) {
      this.userInput = val > 0 ? val : null;
    }
  },
  mounted: function mounted() {
    this.userInput = this.currentPage > 0 ? this.currentPage : null;
  },
  methods: {
    goPage: function goPage(item) {
      if (item < this.pageArr[0]) {
        item = this.pageArr[0];
      }

      if (item > this.pageArr[this.pageArr.length - 1]) {
        item = this.pageArr[this.pageArr.length - 1];
      }

      this.userInput = item > 0 ? item : null;
      this.$emit('currentChange', item);
    },
    // 加载更多的样式
    onMouseenter: function onMouseenter(direction) {
      if (direction === 'left') {
        this.quickprevIconClass = 'el-icon-d-arrow-left';
      } else {
        this.quicknextIconClass = 'el-icon-d-arrow-right';
      }
    },
    handleChange: function handleChange(e) {
      var value = e.target.value; // let value = e.target.value
      // 校验 超过最大数 或者最小数0

      var total = this.pageArr[this.pageArr.length - 1];

      if (value > total) {
        value = total;
        this.userInput = total;
      }

      if (!value || value < 1) {
        value = this.pageArr[0];
        this.goPage(parseInt(value, 10)); // 取整

        this.userInput = null;
        return;
      }

      this.goPage(parseInt(value, 10)); // 取整
    }
  }
};