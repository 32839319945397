var studyCardList = [{
  type: 1,
  title: '学习',
  desc: '通过观看视频讲解，学习知识概念，在观看的过程中，可以边学习边补全笔记',
  btnText: '观看视频讲解',
  next: 'video'
}, {
  type: 3,
  title: '完成',
  desc: '验证刚刚学习的知识概念是否真正理解了，在书上完成作答后可在系统中核对答案',
  btnText: '核对答案',
  next: 'answer'
}, {
  type: 4,
  title: '完成',
  desc: '根据刚刚所学的知识，在书本上完成知识运用题目，完成后可在系统中查看答案与解析',
  btnText: '查看答案与解析',
  next: 'answer'
}];
var practiceInfo = {
  type: 6,
  title: '完成',
  desc: '通过完成练习题组，帮助你真正掌握知识概念，完成后可在系统中查看答案和解析',
  btnText: '查看答案与解析',
  next: 'answer'
};
var wrongInfo = {
  title: '整理错题',
  desc: '恭喜你完成了本节新知识的学习，在学习过程中，产生的错题已经帮你收录在错题本中，你可以在系统首页直接查看',
  btnText: '查看错题本',
  next: 'wrong'
};
var serviceInfo = {
  type: 5,
  title: '获取专属定制服务',
  desc: '在系统提交作答数据后，系统可为你提供定制服务<br/>· 薄弱知识点专项练习<br/>· 已掌握知识点难度提升练习<br/>· 错题相似练习',
  btnText: '定制服务介绍',
  next: 'service'
};
export { studyCardList, wrongInfo, practiceInfo, serviceInfo };