import _typeof from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.object.keys.js";

/*
 * @Author       : wanchao4@xdf.cn
 * @Date         : 2022-02-23 10:32:20
 * @LastEditors  : wanchao4@xdf.cn
 * @LastEditTime : 2022-08-16 16:06:33
 * @Description  : sentry
 */
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

function initSentry() {
  Sentry.init({
    Vue: Vue,
    dsn: 'https://45cd97f69d8148b190b581a537f30869@sentry.staff.xdf.cn//32',
    integrations: [new Integrations.BrowserTracing()],
    environment: 'production',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: "seal_troy_student_web@".concat(process.env.VUE_APP_PACKAGE_VERSION)
  });

  Vue.config.errorHandler = function (err) {
    captureException(err);
  };
}

var prefix = 'student_web';

function setSentryTags(tags) {
  Sentry.configureScope(function (scope) {
    if (tags && _typeof(tags) === 'object') {
      var newTags = {};

      for (var _i = 0, _Object$keys = Object.keys(tags); _i < _Object$keys.length; _i++) {
        var attr = _Object$keys[_i];
        var key = prefix + '_' + attr;
        newTags[key] = tags[attr];
      }

      scope.setTags(newTags);
    }
  });
}

function captureException(err) {
  Sentry.captureException(err);
}

export { initSentry, setSentryTags, captureException };