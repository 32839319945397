import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var env = process.env.VUE_APP_TARGET; // 引导视频的baseUrl

var BASE_URL_MAP = {
  online: "https://".concat(location.host, "/tcscreen/webviewpad/maintenance"),
  pre: "https://".concat(location.host, "/tcscreen/webviewpad/maintenance"),
  test: "https://".concat(location.host, "/tcscreen/").concat(env, "/webviewpad/maintenance")
};
export function toSuspended(haltTimestamp) {
  var url = '';

  if (['online', 'pre'].includes(env)) {
    url = "".concat(BASE_URL_MAP[env], "?haltTimestamp=").concat(haltTimestamp);
  } else {
    url = "https://".concat(location.host, "/tcscreen/").concat(env, "/webviewpad/maintenance?haltTimestamp=").concat(haltTimestamp);
  }

  window.location.replace(url);
}