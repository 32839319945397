/*
 * @Author       : wanchao4@xdf.cn
 * @Date         : 2021-10-28 13:54:02
 * @LastEditors  : zhucaiyun1@xdf.cn
 * @LastEditTime : 2022-07-20 18:10:17
 * @Description  : 首页接口
 */
import { request } from './request/index'; // 激活

export function activate(params, token) {
  return request.post('/study/activation_code/activation', params, {
    noMessage: true
  });
} // 获取查看历史

export function getHistory(params) {
  return request.post('/study/page/history', params);
} // 获取用户专属服务金刚项信息

export function getServices(params) {
  return request.post('/study/exclusiveService', params);
} // 获取自学宝列表

export function getBoxes(params) {
  return request.get('/study/box_list', params);
} // 点击查看引导文档

export function getHelpList(params) {
  return request.post('/resource/guide/query', params);
} // 体验模式api  http://yapi.staff.xdf.cn/project/1407/interface/api/cat_9934
// 获取页面

export function expInfoBox(params) {
  return request.post('/study/exp/info/box', params);
}
export function getConfigurateList(params) {
  return request.post('/resource/video/configurate/list', params);
}
export function getGuideModules(params) {
  return request.post('/study/page/modules', params, {
    noLoading: true
  });
}
export function getExpVideo(params) {
  return request.post('/study/exp/list/video', params, {
    noLoading: true
  });
}