import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Router from 'vue-router';
import routerChecking from './router-checking';
import Layout from '../layout';
/** 核心组件不异步加载，首页页面不异步加载。*/

import Login from '@/views/login';
import ExperienceLogin from '@/views/login/experience-login.vue';
import Active from '@/views/active';
import Select from '@/views/select';
import Home from '@/views/home';
import Study from '@/views/study';
import Review from '@/views/review';
import Service from '@/views/service';
import helpCenter from '@/views/help-center';
import Agreement from '@/views/agreement';
import Privacy from '@/views/privacy';
import UserCenter from '@/views/user-center';
import AboutUS from '@/views/about-us';
import PreviewPdf from '@/views/preview-pdf'; // import PersonalInfo from '@/views/static/personal-info'
// import UserAgreement from '@/views/static/user-agreement'

/**
 * Note: sub-menu only appear when route children.length >= 1 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 *  hideSearchInput: false        是否隐藏搜索码搜索框，默认为false
 *  showSubjectSelection: false   是否展示学科筛选，默认为false
  }
 */

/**
 * 以下路由已经被占用，定义新路由的时候避免冲突，禁止使用！！！！！！！！！！！！！！！！！
 * /pubstatic 用于定义协议
 * /download  用于下载
 * /tcscreen  用于服务项页面
 */

export var routes = [{
  path: '/',
  component: Layout,
  children: [{
    path: '/',
    component: Home
  }, {
    path: '/user-center',
    component: UserCenter,
    name: 'userCenter'
  }, {
    path: '/about-us',
    component: AboutUS,
    name: 'aboutUS'
  }]
}, {
  path: '/login',
  component: Login,
  name: 'login'
}, {
  path: '/experience-login',
  component: ExperienceLogin,
  name: 'experienceLogin'
}, {
  path: '/active',
  component: Active,
  name: 'active'
}, {
  path: '/select',
  component: Select,
  name: 'select'
}, {
  path: '/agreement',
  component: Agreement,
  name: 'agreement'
}, {
  path: '/privacy',
  component: Privacy,
  name: 'privacy'
}, {
  path: '/study',
  component: Layout,
  name: 'study',
  children: [{
    path: '/',
    component: Study
  }]
}, {
  path: '/print-record',
  component: Layout,
  name: 'printRecord',
  children: [{
    path: '/',
    component: function component() {
      return import('@/views/print-record');
    }
  }]
}, {
  path: '/service',
  component: Layout,
  name: 'service',
  children: [{
    path: '/',
    component: Service
  }]
}, {
  path: '/help-center',
  component: helpCenter,
  name: 'helpCenter'
}, {
  path: '/review',
  component: Layout,
  name: 'review',
  children: [{
    path: '/',
    component: Review
  }]
}, {
  path: '/previewPdf',
  component: PreviewPdf,
  name: 'previewPdf'
}];
var router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH,
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: routes
});
router.beforeEach(routerChecking);
export default router;