//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import callService from '@/utils/call-service';
import BoxGuideTip from '@/views/study/components/guide/box-guide-tip.vue';
import { SERVICE_MAP } from '@/views/study/constance.js';
export default {
  name: 'BoxServiceCard',
  props: {
    isLock: {
      // 是否是锁定状态
      type: Boolean,
      default: false
    },
    item: {
      // 服务项
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isCustom: {
      // 是否是专属服务
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      serviceMap: SERVICE_MAP
    };
  },
  computed: {
    lockImg: function lockImg() {
      return this.$resource('ucan_img_study_icon_lock.png');
    },
    // 拿到当前卡片的服务类型
    service: function service() {
      return this.serviceMap[this.item.type];
    }
  },
  methods: {
    handleClick: function handleClick() {
      if (this.isLock) {
        // 锁定无法提交
        return this.$showModal(BoxGuideTip, {}, {
          title: '定制服务还未解锁',
          noFooter: true,
          width: '800px',
          customClass: 'overflow-y-hidden'
        });
      }

      callService(this.item.url, this);
    }
  }
};