import "core-js/modules/es.json.stringify.js";

/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2021-11-19 12:58:26
 * @LastEditors  : wanchao4@xdf.cn
 * @LastEditTime : 2022-07-22 12:37:57
 * @Description  : 描述信息
 */
export default {
  state: {
    subject: localStorage.getItem('subject') || '',
    // 当前系统选择的学科
    preciousMessage: localStorage.getItem('preciousMessage') || {},
    // 自学宝的信息，调turn接口用
    userActionNewZxbInfo: null,
    // 用来临时保存用户在交互码弹框里的选择信息，等弹框关闭再更新
    studyBindSuccess: 0,
    // 绑定自学宝成功就+1
    userActionZxbInfo: localStorage.getItem('preciousInformation') || {},
    // 用户手动选择过的各学科自学宝集合
    username: localStorage.getItem('username'),
    userAvatar: localStorage.getItem('userAvatar'),
    userActionCloseCodeDialog: false,
    // 用户关闭交互码弹框，通知个页面刷新数据
    showPageCue: false,
    userMode: localStorage.getItem('userMode') || 'normal' // 用户登陆方式 normal(正常登陆) ｜ experience(体验登陆)

  },
  mutations: {
    SET_SUBJECT: function SET_SUBJECT(state, args) {
      localStorage.setItem('subject', args);
      state.subject = args;
    },
    SET_PRECIOUS_MESSAGE: function SET_PRECIOUS_MESSAGE(state, args) {
      localStorage.setItem('preciousMessage', JSON.stringify(args));
      state.preciousMessage = JSON.stringify(args);
    },
    STUDY_BIND_SUCCESS: function STUDY_BIND_SUCCESS(state, args) {
      state.studyBindSuccess = args;
    },
    SET_PRECIOUS_INFO: function SET_PRECIOUS_INFO(state, args) {
      localStorage.setItem('preciousInformation', JSON.stringify(args));
      state.userActionZxbInfo = JSON.stringify(args);
    },
    UPDATE_PRECIOUS_INFO: function UPDATE_PRECIOUS_INFO(state, args) {
      state.userActionNewZxbInfo = args;
    },
    SET_USERNAME: function SET_USERNAME(state, args) {
      state.username = args;
      localStorage.setItem('username', args);
    },
    SET_USER_AVATAR: function SET_USER_AVATAR(state, args) {
      state.userAvatar = args;
      localStorage.setItem('userAvatar', args);
    },
    CLOSE_CODEDIALOG: function CLOSE_CODEDIALOG(state, args) {
      state.userActionCloseCodeDialog = args;
    },
    SET_Show_Page_Cue: function SET_Show_Page_Cue(state, args) {
      state.showPageCue = args;
    },
    SET_USER_MODE: function SET_USER_MODE(state, args) {
      state.userMode = args;
    }
  },
  actions: {
    setSubject: function setSubject(_ref, args) {
      var commit = _ref.commit;
      commit('SET_SUBJECT', args);
    },
    setPreciousMessage: function setPreciousMessage(_ref2, args) {
      var commit = _ref2.commit;
      commit('SET_PRECIOUS_MESSAGE', args);
    },
    setStudyBindSuccess: function setStudyBindSuccess(_ref3, args) {
      var commit = _ref3.commit;
      commit('STUDY_BIND_SUCCESS', args);
    },
    setPreciousInfo: function setPreciousInfo(_ref4, args) {
      var commit = _ref4.commit;
      commit('SET_PRECIOUS_INFO', args);
    },
    toUpdatePreciousInfo: function toUpdatePreciousInfo(_ref5, args) {
      var commit = _ref5.commit;
      commit('UPDATE_PRECIOUS_INFO', args);
    },
    setUsername: function setUsername(_ref6, args) {
      var commit = _ref6.commit;
      commit('SET_USERNAME', args);
    },
    setUserAvatar: function setUserAvatar(_ref7, args) {
      var commit = _ref7.commit;
      commit('SET_USER_AVATAR', args);
    },
    closeInteractionCodeDialog: function closeInteractionCodeDialog(_ref8, args) {
      var commit = _ref8.commit;
      commit('CLOSE_CODEDIALOG', args);
    },
    setShowPageCue: function setShowPageCue(_ref9, args) {
      var commit = _ref9.commit;
      commit('SET_Show_Page_Cue', args);
    },
    setUserMode: function setUserMode(_ref10, args) {
      var commit = _ref10.commit;
      commit('SET_USER_MODE', args);
      localStorage.setItem('userMode', args);
    }
  }
};