/*
 * @Author       : wangkai42@xdf.cn
 * @Date         : 2022-03-08 13:25:07
 * @LastEditors  : wangkai42@xdf.cn
 * @LastEditTime : 2022-03-08 13:26:18
 * @Description  : 描述信息
 */
import { request } from '../request'; // 查看引导文档,具体内容在content；content含义详见 http://yapi.staff.xdf.cn/project/1407/interface/api/80624

export var guideQuery = function guideQuery(params) {
  return request.post('/resource/guide/query', params);
}; // 根据resourceCode获取是否展示引导项,单个传参，首次引导视频 http://yapi.staff.xdf.cn/project/1407/interface/api/80612

export var getFirstGuide = function getFirstGuide(params) {
  return request.post("/resource/guide/policy", params);
};