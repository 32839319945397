import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'IframeWeb',
  props: {
    url: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      default: 0
    },
    iframeName: {
      type: String,
      default: ''
    }
  },
  created: function created() {
    if (this.iframeName === 'guide') this.$bus.$on('closeGuideDialog', this.handleClose);
  },
  destroyed: function destroyed() {
    if (this.iframeName === 'guide') this.$bus.$off('closeGuideDialog');
  },
  methods: {
    handleClose: function handleClose() {
      this.$attrs.close();
      this.$bus.$off('closeGuideDialog');
    }
  }
};