//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ItemTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    intro: {
      type: String,
      default: ''
    }
  }
};