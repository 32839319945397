//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Filing',
  props: {
    bottom: Boolean
  }
};