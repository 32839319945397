//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BoxProgressTips',
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      study_tip: {
        title: '卡片',
        content: ['学习分册中的「知识探索」「理解验证」「知识运用」', '练习分册中的「练习题」']
      },
      exam_tip: {
        title: '需提交的练习题',
        content: ['学习分册中的「知识运用」', '练习分册中的「练习题」']
      }
    };
  },
  computed: {
    tip: function tip() {
      return this.from === 'study' ? this.study_tip : this.exam_tip;
    }
  },
  methods: {
    // 全屏幕点击事件
    cancel: function cancel(e) {
      var target = e.target;

      if (this.$refs.tipbox !== target && !this.$refs.tipbox.contains(target)) {
        this.cancelTip();
      }
    },
    // 去tip弹窗
    cancelTip: function cancelTip() {
      this.$emit('cancelTip');
    }
  }
};