import outsider from './outsider/index.js';
import focus from './focus/index.js';
import fade from './fade/index.js';
import bury from './bury/index.js';

var install = function install(Vue) {
  Vue.directive('outsider', outsider);
  Vue.directive('focus', focus);
  Vue.directive('fade', fade);
  Vue.directive('bury', bury);
};

export default install;