//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ProgressSingleGuan',
  components: {},
  props: {
    data: {
      type: Object,
      default: function _default() {}
    },
    isDetail: {
      type: Boolean,
      default: true
    },
    isQia: {
      // 是否表示关卡，只有表示关卡的时候父元素才传true进来，其余情况下不传此prop。
      type: Boolean,
      default: false
    }
  },
  computed: {
    showGuanInfo: function showGuanInfo() {
      var _this$data, _this$data$cardProgre, _this$data2, _this$data2$exerciseP;

      return ((_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$cardProgre = _this$data.cardProgress) === null || _this$data$cardProgre === void 0 ? void 0 : _this$data$cardProgre.total) || ((_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$exerciseP = _this$data2.exerciseProgress) === null || _this$data2$exerciseP === void 0 ? void 0 : _this$data2$exerciseP.total);
    },
    outLineName: function outLineName() {
      var _this$data3 = this.data,
          type = _this$data3.type,
          outLineSeqName = _this$data3.outLineSeqName,
          outLineName = _this$data3.outLineName;

      if (type === 3 || type === 4) {
        // 本章练习或者本关练习
        return outLineSeqName;
      } else {
        return outLineSeqName + '：' + outLineName;
      }
    }
  },
  methods: {
    // 跳转去对应的节点
    jumpToOutLine: function jumpToOutLine() {
      this.$emit('nodeClick', this.data);
    }
  }
};