//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import {
// } from '@/api/login'
import PageTitle from '@/components/page-title.vue';
export default {
  name: 'AboutUS',
  components: {
    PageTitle: PageTitle
  },
  data: function data() {
    return {};
  },
  methods: {
    toUserAgreement: function toUserAgreement() {
      // this.$router.push('./agreement')
      this.$router.push({
        path: '/agreement',
        query: {
          showBack: 1
        }
      });
    },
    toPersonalInfo: function toPersonalInfo() {
      // this.$router.push('./privacy')
      this.$router.push({
        path: '/privacy',
        query: {
          showBack: 1
        }
      });
    },
    goBack: function goBack() {
      this.$router.push({
        path: '/'
      });
    }
  }
};