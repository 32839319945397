import "core-js/modules/es.json.stringify.js";

/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2021-11-19 13:29:49
 * @LastEditors  : zhucaiyun1@xdf.cn
 * @LastEditTime : 2022-06-28 11:42:27
 * @Description  : 描述信息 ? JSON.parse(state.localUser.preciousMessage) : {}
 */
var getters = {
  subjectGetters: function subjectGetters(state) {
    return +state.localUser.subject;
  },
  preciousMessage: function preciousMessage(state) {
    return JSON.stringify(state.localUser.preciousMessage) !== '{}' ? JSON.parse(state.localUser.preciousMessage) : {};
  },
  userActionNewZxbInfo: function userActionNewZxbInfo(state) {
    return state.localUser.userActionNewZxbInfo;
  },
  studyBindSuccess: function studyBindSuccess(state) {
    return state.localUser.studyBindSuccess;
  },
  userActionZxbInfo: function userActionZxbInfo(state) {
    return JSON.stringify(state.localUser.userActionZxbInfo) !== '{}' ? JSON.parse(state.localUser.userActionZxbInfo) : {};
  },
  unBindSubject: function unBindSubject(state) {
    return state.home.unBindSubject;
  },
  username: function username(state) {
    return state.localUser.username;
  },
  userAvatar: function userAvatar(state) {
    return state.localUser.userAvatar;
  },
  closeCodeDialog: function closeCodeDialog(state) {
    return state.localUser.userActionCloseCodeDialog;
  },
  serviceTitle: function serviceTitle(state) {
    return state.home.serviceTitle;
  },
  showPageCue: function showPageCue(state) {
    return state.localUser.showPageCue;
  },
  progressSelectNode: function progressSelectNode(state) {
    return state.home.progressSelectNode;
  },
  maskLoadingVisible: function maskLoadingVisible(state) {
    return state.home.maskLoadingVisible;
  },
  userMode: function userMode(state) {
    return state.localUser.userMode;
  },
  expandExperience: function expandExperience(state) {
    return state.home.expandExperience;
  },
  experienceInfos: function experienceInfos(state) {
    return state.home.experienceInfos;
  },
  experienceBook: function experienceBook(state) {
    return state.home.experienceBook;
  }
};
export default getters;