//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CardItem',
  props: {
    cardData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      cardTypeMap: {
        1: '今日新卡',
        2: '今日复习卡'
      }
    };
  }
};