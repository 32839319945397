/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2022-03-28 15:03:27
 * @LastEditors  : wanchao4@xdf.cn
 * @LastEditTime : 2022-07-13 14:25:46
 * @Description  : 描述信息
 */
import { request } from '../request'; // 埋点,https://yapi.staff.xdf.cn/project/1407/interface/api/80708

export var resourceBury = function resourceBury(params) {
  return request.post('/resource/bury', params, {
    noLoading: true
  });
}; // 埋点 不需要登陆

export var buryWithoutLogin = function buryWithoutLogin(params) {
  return request.post('/resource/front/nake/bury', params, {
    noLoading: true
  });
}; // 埋点 需要登陆

export var buryWithLogin = function buryWithLogin(params) {
  return request.post('/resource/front/bury', params, {
    noLoading: true
  });
};