import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.split.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
var isMatch = function isMatch() {
  var _chromeVersion$, _chromeVersion$$split;

  var ua = navigator.userAgent;
  var chromeVersion = ua.match(/chrome\/([\d\.]+)/gi);
  var isLowVersion = chromeVersion && ((_chromeVersion$ = chromeVersion[0]) === null || _chromeVersion$ === void 0 ? void 0 : (_chromeVersion$$split = _chromeVersion$.split('/')[1]) === null || _chromeVersion$$split === void 0 ? void 0 : _chromeVersion$$split.split('.')[0]) < 52;
  return ua.match(/msie\s([\d\.]+)/gi) || // ie浏览器
  ua.match(/Trident.*rv:11./gi) || // ie11
  isLowVersion; // 低版本edge, 用户版本 Chrome/51.0.2704.79
};

export default {
  name: 'IETip',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isShow: isMatch()
    };
  },
  methods: {
    download: function download() {
      window.open(this.$resource('ChromeSetup.exe', 'chrome'), '_blank');
    }
  }
};