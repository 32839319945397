/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2021-11-15 15:30:48
 * @LastEditors  : zhangying@xdf.cn
 * @LastEditTime : 2021-12-03 17:46:03
 * @Description  : 描述信息
 */
import { request } from '../request'; // 页码跳转

export var getBoxPageList = function getBoxPageList(params) {
  return request.post("/study/box/page/turn", params);
}; // 页码跳转_所属自学宝选择

export var getPageBoxList = function getPageBoxList(params) {
  return request.get("/study/box_list/all", params, {
    noLoading: true
  });
};