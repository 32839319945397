import _toConsumableArray from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BoxMainOutlineCard from '@/views/study/components/box-main-outline-card.vue';
import BoxMainOutlineQia from '@/views/study/components/box-main-outline-qia.vue';
import DarkBg from '@/components/dark-bg.vue';
export default {
  name: 'BoxMainOutlineCollapse',
  components: {
    DarkBg: DarkBg,
    BoxMainOutlineCard: BoxMainOutlineCard,
    BoxMainOutlineQia: BoxMainOutlineQia
  },
  props: {
    selectNode: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    node: {
      type: Object,
      default: function _default() {}
    },
    expandNodes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    states: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      activeName: []
    };
  },
  computed: {
    hideNode: function hideNode() {
      // 隐藏节点
      if (this.states.some(function (state) {
        return state.select && state.status === 0;
      }) || this.states.every(function (st) {
        return st.select;
      }) || this.states.every(function (st) {
        return !st.select;
      })) {
        return false;
      } // 1未全部提交2全部提交


      var _this$states$find = this.states.find(function (s) {
        return s.select;
      }),
          status = _this$states$find.status;

      if (status === 1) {
        return this.node.status === 2;
      } else if (this.node.status === 2) {
        return false;
      }

      var _this$node = this.node,
          children = _this$node.children,
          virtualNode = _this$node.virtualNode; // 内部一个等于2（全部提交）的都没有

      var nodes = (children || []).concat(virtualNode || []);
      return nodes.every(function (n) {
        return n.status !== 2;
      });
    },
    open: function open() {
      return this.activeName.includes(this.node.outLineId);
    },
    colorOptions: function colorOptions() {
      var isOpen = this.activeName.includes(this.node.outLineId);
      return {
        color0: isOpen ? 'rgba(137, 197, 183, 0.6)' : 'transparent',
        // 左上
        color1: isOpen ? 'rgba(137, 197, 183, 0.6)' : 'transparent',
        // 右上
        color2: isOpen ? 'rgba(81, 147, 129, 0.36)' : 'transparent',
        // 右下
        color3: isOpen ? 'rgba(81, 147, 129, 0.36)' : 'transparent' // 左下

      };
    }
  },
  created: function created() {
    this.activeName = _toConsumableArray(this.expandNodes);
  },
  methods: {
    nodeClick: function nodeClick(node) {
      this.$emit('nodeClick', node);
    },
    openCloseColl: function openCloseColl() {
      // 更新展开的节点信息
      this.$emit('expandCollNode', this.node.outLineId);
    }
  }
};