import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ModalSwitchBook',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    boxId: {
      type: [String, Number],
      default: ''
    },
    boxes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isShow: true,
      active: this.boxId
    };
  },
  methods: {
    confirmChange: function confirmChange() {
      var _this = this;

      var item = this.boxes.find(function (i) {
        return i.boxId === +_this.active;
      });
      this.$emit('handleChangeBox', this.active, item);
      this.$emit('update:visible', false);
    },
    handlerClosed: function handlerClosed() {
      this.$emit('update:visible', false);
    }
  }
};