import { request } from './request/index'; // 根据手机号发送验证码

export function sendVerifyCode(params, token) {
  return request.post('/account/send_verify_code', params, {
    headers: {
      'mmvToken': token
    }
  });
} // 根据验证码登录

export function loginVerifyCode(params) {
  return request.post('/account/user_login_verify_code', params);
} // 完善用户信息

export function userInfoComplete(params) {
  return request.post('/account/user_info_complete', params);
} // 获取省、市、区、年级信息

export function getAreaGradeInfo(params) {
  return request.post('/account/get_area_grade_info', params);
} // 获取区域下学校信息

export function getAreaSchoolInfo(params) {
  return request.post('/account/get_area_school_info', params);
} // 用户详情信息

export function getUserInfoDetail(params) {
  return request.post('/account/user_info_detail', params);
} // 密码登陆

export function loginByPassword(params) {
  return request.post('/account/user_login_password', params);
} // 忘记密码-发送验证码

export function verifyCodeByForgetpassword(params, token) {
  return request.post('/account/forget_password/send_verify_code', params, {
    headers: {
      'mmvToken': token
    }
  });
} // 忘记密码

export function forgetPassword(params) {
  return request.post('/account/forget_password', params);
}
/**
 * 修改密码发送验证码
 * @param {*} params phone:手机号(加密密文，AES算法)  mobile: 手机号(web端参数，手机号明文)
 * @param {*} token
 * @returns
 */

export function ajaxUpdateSendVerifyCode(params, token) {
  return request.post('/account/update_password/send_verify_code', params, {
    headers: {
      'mmvToken': token
    }
  });
}
/**
 * 修改密码
 * @param {*} params uid	verifyCode	password
 * @returns
 */

export function ajaxUpdatePwd(params) {
  return request.post('/account/update_password', params);
}
/**
 * 判断验证码(忘记密码)
 * @param {*} params verifyCode mobile
 * @returns
 */

export function ajaxValidateVerifyCode(params) {
  return request.post('/account/validate_verify_code', params);
}
/**
 * 判断验证码(修改密码)
 * @param {*} params verifyCode
 * @returns
 */

export function ajaxTokenValidateVerifyCode(params) {
  return request.post('/account/token/validate_verify_code', params);
} // 体验帐号重置接口

export function resetAccount(params) {
  return request.post('/manager/account/reset', params);
} // 登出清除cookie

export function layoutApi() {
  return request.post('/account/user_logout');
} // 体验模式下激活切换成正常登陆

export function activeUser() {
  return request.post('/account/exp/active');
}