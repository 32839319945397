import axios from 'axios';
import { blocker } from './utils';
var CancelToken = axios.CancelToken;
var defaultConfig = {};
defaultConfig.cancelToken = new CancelToken(function executor(c) {
  blocker.addPending(c);
});
axios.defaults.withCredentials = true;
var config = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 600000,
  defaultConfig: defaultConfig
}; // 返回一个axios实例，能直接用，但是request和response都不做拦截

var service = axios.create(config); // request拦截

service.interceptors.request.use(blocker.blockRequest.bind(blocker), blocker.blockRequestError, {
  synchronous: true
}); // response拦截

service.interceptors.response.use(blocker.blockResponse, blocker.blockResponseError);
export { service };