import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BoxMainOutlineCardTag from '@/views/study/components/box-main-outline-card-tag.vue';
export default {
  name: 'BoxMainOutlineZhang',
  components: {
    BoxMainOutlineCardTag: BoxMainOutlineCardTag
  },
  props: {
    chapter: {
      type: Object,
      default: function _default() {}
    },
    selectNode: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      scrollStyle: ''
    };
  },
  mounted: function mounted() {
    var elWidth = this.$refs.virtualNodeName.offsetWidth;

    if (elWidth > 94) {
      this.scrollStyle = {
        animationDuration: "".concat(elWidth / 200 * 5, "s")
      };
    }
  },
  methods: {
    isChapterClick: function isChapterClick(chapter) {
      return (!chapter.children || chapter.children && !chapter.children.length) && (!chapter.virtualNode || chapter.virtualNode && !chapter.virtualNode.length);
    },
    activeNode: function activeNode(chapter) {
      if (!this.selectNode) {
        return false;
      }

      var virtualId = chapter.virtualId,
          type = chapter.type,
          outLineId = chapter.outLineId;
      var _this$selectNode = this.selectNode,
          seOutLineId = _this$selectNode.outLineId,
          seVirtualId = _this$selectNode.virtualId,
          seType = _this$selectNode.type;
      return "".concat(virtualId, "_").concat(type, "_").concat(outLineId) === "".concat(seVirtualId, "_").concat(seType, "_").concat(seOutLineId);
    },
    chapterClick: function chapterClick(chapter) {
      if (!this.isChapterClick(chapter)) return;
      this.$emit('chapterClick', chapter);
    }
  }
};