//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack: function goBack() {
      this.$emit('goback');
    }
  }
};