import _typeof from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";

/**
/**
 * 静态文件资源地址
 * @param {*} name 名称
 * @param {*} path 路径
 * @param {*} type 类型
 * @returns 完整路径
 */
export var resource = function resource(name) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'img';
  return "https://ucanos.xdf.cn/troy/static_resources/seal_troy_student_web/".concat(type, "/").concat(name);
};
/**

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    return value.toString().padStart(2, '0');
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
} // 防抖函数

export function debounce(fn) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}
export var stringPathToObject = function stringPathToObject(path) {
  var urlParams = new URLSearchParams(path);
  return Array.from(urlParams.keys()).reduce(function (result, key) {
    result[key] = urlParams.get(key);
    return result;
  }, {});
};
/**
 * 数字转汉字函数
 */

export function parseChinese(num) {
  var chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var chnUnitSection = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿'];
  var chars = num.toString().split('');
  var str = '';

  for (var i = 0, l = chars.length; i < l; i++) {
    var des_i = chars.length - 1 - i;
    str = chnUnitSection[i] + str;
    var charindex = chars[des_i];
    str = chnNumChar[charindex] + str;
  } // 将【零千、零百】换成【零】 【十零】换成【十】


  str = str.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十'); // 合并中间多个零为一个零

  str = str.replace(/零+/g, '零'); // 将【零亿】换成【亿】【零万】换成【万】

  str = str.replace(/零亿/g, '亿').replace(/零万/g, '万'); // 移除末尾的零

  str = str.replace(/零+$/, '');
  str = str.replace(/零一十/g, '零十');
  str = str.replace(/^一十/g, '十');
  return str;
}
/**
 * @Author: wangkai42@xdf.cn
 * @LastEditors: wangkai42@xdf.cn
 * @description: 校验手机号
 * @param {*}
 * @return {*}
 */

export function checkPhone(phone) {
  if (!/^1\d{10}$/.test(phone)) {
    return false;
  }

  return true;
}
/**
 * @Author: wanchao
 * @LastEditors: wanchao
 * @description: 获取参数值
 * @param {*}
 * @return {*}
 */

export function changeURLSearch(url, arg, value) {
  var pattern = arg + '=([^&]*)';
  var replaceText = arg + '=' + value;

  if (url.match(pattern)) {
    var tmp = '/(' + arg + '=)([^&]*)/gi'; // eslint-disable-next-line no-eval

    return url.replace(eval(tmp), replaceText);
  } else {
    if (url.match('[\?]')) {
      return url + '&' + replaceText;
    } else {
      return url + '?' + replaceText;
    }
  }
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function foramtDate(time) {
  if (!time) {
    return '';
  }

  var start = new Date(time);
  var startY = start.getFullYear();
  var startM = start.getMonth();
  var startD = start.getDate();
  var now = new Date();
  var nowY = now.getFullYear();
  var nowM = now.getMonth();
  var nowD = now.getDate();

  if (nowY === startY && nowM === startM) {
    if (nowD === startD) {
      return '今天';
    } else if (nowD - startD === 1) {
      return '昨天';
    }
  }

  return startM + 1 + '-' + startD;
}
/**
 * 获取当前光标位置
 * @param ctrl
 * @returns {number}
 */

export function getCursortPosition(element) {
  var CaretPos = 0;

  if (document.selection) {
    // 支持IE
    element.focus();
    var Sel = document.selection.createRange();
    Sel.moveStart('character', -element.value.length);
    return Sel.text.length;
  } else if (element.selectionStart || +element.selectionStart === 0) {
    return element.selectionStart;
  }

  return CaretPos;
}
/**
 * 设置光标位置
 * @param ctrl
 * @param pos
 */

export function setCaretPosition(element, pos) {
  if (element.setSelectionRange) {
    element.focus();
    element.setSelectionRange(pos, pos);
  } else if (element.createTextRange) {
    var range = element.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
} //  延时函数sleep

export function sleep(time) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve();
    }, time);
  });
}
export function format(fmt) {
  var o = {
    'M+': this.getMonth() + 1,
    // 月份
    'd+': this.getDate(),
    // 日
    'h+': this.getHours(),
    // 小时
    'm+': this.getMinutes(),
    // 分
    's+': this.getSeconds(),
    // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3),
    // 季度
    'S': this.getMilliseconds() // 毫秒

  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }

  return fmt;
}
export function deepCody() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var newObj = {};

  if (_typeof(obj) === 'object' && obj !== null) {
    newObj = obj instanceof Array ? [] : {};

    for (var i in obj) {
      newObj[i] = deepCody(obj[i]);
    }
  } else {
    newObj = obj;
  }

  return newObj;
}