import _toConsumableArray from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import OutlineCache from '@/utils/OutlineCache.js';
import BoxProgressTips from '@/views/study/components/progress/box-progress-tips.vue';
import ProgressGuanCollapse from '@/views/study/components/progress/progress-guan-collapse.vue';
import ProgressSingleGuan from '@/views/study/components/progress/progress-single-guan.vue';
var cache = OutlineCache.getInstance();
export default {
  name: 'BoxMainProgress',
  components: {
    BoxProgressTips: BoxProgressTips,
    ProgressGuanCollapse: ProgressGuanCollapse,
    ProgressSingleGuan: ProgressSingleGuan
  },
  props: {
    serviceProgress: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    subjectCode: {
      type: Number,
      default: 0
    },
    boxId: {
      type: [String, Number],
      default: ''
    },
    selectNode: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    expandNodes: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      edu: JSON.parse(localStorage.getItem('userInfo')).edu,
      tipFrom: 'study',
      showTip: false,
      expandNodesTemp: this.expandNodes
    };
  },
  computed: {
    // 此时是本关服务
    service_guan: function service_guan() {
      var selectNode = this.selectNode;
      return selectNode.type === 1;
    },
    // 此时是本章服务
    service_zhang: function service_zhang() {
      var selectNode = this.selectNode;
      return selectNode.type === 2;
    },
    // 把真实节点（关卡，关）跟虚拟节点（本关练习、本章练习）concat起来
    childrenAndVirtual: function childrenAndVirtual() {
      var serviceProgress = this.serviceProgress;
      var c = (serviceProgress === null || serviceProgress === void 0 ? void 0 : serviceProgress.children) || [];
      var v = (serviceProgress === null || serviceProgress === void 0 ? void 0 : serviceProgress.virtualNode) || [];
      return c.concat(v);
    }
  },
  watch: {
    expandNodes: function expandNodes() {
      this.expandNodesTemp = this.expandNodes;
    }
  },
  methods: {
    // 展示tip
    showTipModal: function showTipModal(from) {
      this.showTip = true;
      this.tipFrom = from;
    },
    // 取消tip
    cancelTip: function cancelTip() {
      this.showTip = false;
    },
    // 折叠
    expandCollNode: function expandCollNode(outlineId) {
      var idx = this.expandNodesTemp.findIndex(function (i) {
        return i === outlineId;
      });

      if (idx !== -1) {
        // 收起
        this.expandNodesTemp.splice(idx, 1);
      } else {
        // 展开
        this.expandNodesTemp.push(outlineId);
        this.expandNodesTemp = _toConsumableArray(new Set(this.expandNodesTemp));
      }

      cache.changeProgressOpenNodes(this.edu, this.subjectCode, this.boxId, this.expandNodesTemp);
    },
    nodeClick: function nodeClick(node) {
      this.$store.dispatch('setProgressSelectNode', node); // 缓存更新选中节点

      cache.changeOutline(this.edu, this.subjectCode, this.boxId, node);
    }
  }
};