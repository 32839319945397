import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _typeof from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/typeof";

/*
 * @Author       : wanchao4@xdf.cn
 * @Date         : 2022-07-13 11:26:56
 * @LastEditors  : wanchao4@xdf.cn
 * @LastEditTime : 2022-07-13 14:46:48
 * @Description  : 添加埋点
 */
import { buryWithoutLogin, buryWithLogin } from '@/api/modules/bury';
var params = {};

var initParams = function initParams(binding) {
  var value = binding.value;

  if (value && _typeof(value) === 'object') {
    params = _objectSpread(_objectSpread({}, value), {}, {
      time: new Date().toLocaleString()
    });
  }
};

export default {
  // 初始化指令
  bind: function bind(el, binding, vnode) {
    // 初始化参数
    initParams(binding); // 调用接口

    function bury(e) {
      var _params = params,
          unNeedLogin = _params.unNeedLogin;
      unNeedLogin ? buryWithoutLogin(params) : buryWithLogin(params);
    } // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听


    el.__vueClickBury__ = bury;
    el.addEventListener('click', bury);
  },
  update: function update(el, binding) {
    // 参数变化
    initParams(binding);
  },
  unbind: function unbind(el, binding) {
    // 解除事件监听
    el.removeEventListener('click', el.__vueClickBury__);
    delete el.__vueClickBury__;
  }
};