import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SERVICE_STATUS_MAP } from '@/views/study/constance.js';
export default {
  name: 'BoxServiceStatus',
  props: {
    serviceTagInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      serviceStatusMap: SERVICE_STATUS_MAP
    };
  },
  computed: {
    serviceStatus: function serviceStatus() {
      var lockStatus = this.serviceTagInfo.lockStatus;
      var l = {};
      this.serviceStatusMap.forEach(function (ele) {
        if (ele.lockStatus === lockStatus) {
          l = ele;
        }
      });
      return l;
    },
    // 掌握程度颜色变化
    percentStyle: function percentStyle() {
      var persentage = this.serviceTagInfo.persentage; // 掌握程度

      var m = parseInt(persentage);
      var c = '';

      if (m < 30) {
        c = '#FF6155';
      } else if (m >= 30 && m < 75) {
        c = '#FF870E';
      } else if (m >= 75) {
        c = '#32C86D';
      }

      return {
        backgroundColor: c
      };
    }
  },
  watch: {},
  methods: {}
};