import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex'; // import XdfInput from '@/views/home/compontents/xdf-input.vue'

import DarkBg from '@/components/dark-bg.vue';
import SelectTextbook from '@/components/select-textbook.vue';
export default {
  components: {
    DarkBg: DarkBg,
    // XdfInput,
    SelectTextbook: SelectTextbook
  },
  model: {
    prop: 'code',
    event: 'update'
  },
  props: {
    pattern: {
      type: String,
      default: 'small'
    },
    code: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      activateCode: '',
      // 激活码
      timer: null
    };
  },
  computed: _objectSpread({}, mapGetters(['preciousMessage', 'showPageCue' // 在这里输入页码的提示显示逻辑
  ])),
  watch: {
    code: {
      immediate: true,
      handler: function handler(newVal) {
        this.activateCode = newVal;
      }
    },
    activateCode: function activateCode(val) {
      this.activateCode = val.replace(/[^0-9]/g, '');
    },
    // 3s 后隐藏提示
    showPageCue: function showPageCue(val) {
      var _this = this;

      if (val) {
        this.$refs.codeInput.focus();
        this.timer = setTimeout(function () {
          return _this.$store.dispatch('setShowPageCue', false);
        }, 3000);
        setTimeout(function () {
          document.addEventListener('click', _this.documentListener); // 页码提示框点击其他区域消失监听
        });
      } else {
        document.removeEventListener('click', this.documentListener);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    documentListener: function documentListener(e) {
      if (e.target.innerText !== '输入页码' && e.target.className !== 'cue-dialog') {
        this.closePageCue();
      }
    },
    getTurn: function getTurn(val) {
      this.activateCode = val;
      this.showContentDialog();
    },
    showContentDialog: function showContentDialog() {
      if (!this.activateCode) {
        this.$message.error('请输入页码后，再进行检索');
        return;
      }

      this.$emit('handleShowiframeDialog', _objectSpread({
        page: this.activateCode
      }, this.preciousMessage));
      this.activateCode = '';
    },
    inputBlur: function inputBlur() {
      this.$refs.codeInput.blur();
      this.activateCode = '';
    },
    // 关闭提示
    closePageCue: function closePageCue() {
      clearTimeout(this.timer);
      this.$store.dispatch('setShowPageCue', false);
    }
  }
};