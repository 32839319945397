//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'TotalNum',
  props: {
    totalData: {
      type: Object,
      default: function _default() {}
    }
  }
};