//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'LoadingMusk',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};