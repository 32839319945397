//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Subject',
  props: {
    subjects: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selected: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    active: function active() {
      return this.selected ? this.selected.code : 0;
    }
  },
  methods: {
    handleClick: function handleClick(subject) {
      this.$emit('onChange', subject);
    }
  }
};