import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BoxMainOutlineCardTag from '@/views/study/components/box-main-outline-card-tag.vue';
export default {
  name: 'BoxMainOutlineQia',
  components: {
    BoxMainOutlineCardTag: BoxMainOutlineCardTag
  },
  props: {
    selectNode: {
      type: Object,
      default: function _default() {}
    },
    sectionName: {
      // 关/章名称，用来拼接parentName
      type: String,
      default: ''
    },
    qia: {
      type: Object,
      default: function _default() {},
      require: true
    },
    states: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    activeNode: function activeNode() {
      if (!this.selectNode) {
        return false;
      }

      var _this$qia = this.qia,
          outLineId = _this$qia.outLineId,
          virtualId = _this$qia.virtualId,
          type = _this$qia.type;

      if (type === 3) {
        // 本关练习
        return "".concat(this.selectNode.virtualId, "_").concat(this.selectNode.type) === "".concat(virtualId, "_").concat(type);
      }

      return this.selectNode.outLineId === outLineId;
    },
    checkImg: function checkImg() {
      return this.$resource('ucan_img_study_icon_submit.png');
    },
    hideNode: function hideNode() {
      // 隐藏节点
      if (this.states.some(function (state) {
        return state.select && state.status === 0;
      }) || this.states.every(function (st) {
        return st.select;
      }) || this.states.every(function (st) {
        return !st.select;
      })) {
        return false;
      }

      var _this$states$find = this.states.find(function (s) {
        return s.select;
      }),
          status = _this$states$find.status;

      return status !== this.qia.status;
    },
    qiaTitle: function qiaTitle() {
      var _this$qia2 = this.qia,
          sectionSeq = _this$qia2.sectionSeq,
          subSectionSeq = _this$qia2.subSectionSeq,
          type = _this$qia2.type;

      if (type === 3) {
        return '本关练习';
      }

      return "\u5173\u5361".concat(sectionSeq, "-").concat(subSectionSeq);
    }
  },
  methods: {
    nodeClick: function nodeClick() {
      this.qia.parentName = this.sectionName;
      this.$emit('nodeClick', this.qia);
    }
  }
};