import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BoxMainStatus from '@/views/study/components/box-main-status.vue';
import BoxMainOutline from '@/views/study/components/box-main-outline.vue';
import BoxMainService from '@/views/study/components/box-main-service.vue';
import ModalSwitchBook from '@/views/study/components/modal-switch-book.vue';
import DarkBg from '@/components/dark-bg.vue';
import { SUBMIT_STATUS } from '@/views/study/constance.js';
import OutlineCache from '@/utils/OutlineCache.js';
var cache = OutlineCache.getInstance();
export default {
  name: 'BoxMain',
  components: {
    DarkBg: DarkBg,
    BoxMainStatus: BoxMainStatus,
    BoxMainService: BoxMainService,
    BoxMainOutline: BoxMainOutline,
    ModalSwitchBook: ModalSwitchBook
  },
  props: {
    boxId: {
      type: [String, Number],
      default: ''
    },
    subjectCode: {
      type: Number,
      default: 0
    },
    boxes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      states: SUBMIT_STATUS,
      selectNode: {},
      boxName: '',
      edu: JSON.parse(localStorage.getItem('userInfo')).edu,
      showBookModal: false
    };
  },
  computed: {
    outlineName: function outlineName() {
      if (!this.selectNode) {
        return '';
      }

      var _this$selectNode = this.selectNode,
          sectionSeq = _this$selectNode.sectionSeq,
          subSectionSeq = _this$selectNode.subSectionSeq,
          outLineSeqName = _this$selectNode.outLineSeqName,
          outLineName = _this$selectNode.outLineName,
          type = _this$selectNode.type,
          name = _this$selectNode.name,
          outLineType = _this$selectNode.outLineType;

      if (outLineType === 3) {
        // 关卡
        return "\u5173\u5361".concat(sectionSeq, "-").concat(subSectionSeq, "\uFF1A").concat(outLineName);
      } else if (type === 4) {
        // 章练习
        return "\u672C\u7AE0\u7EC3\u4E60\uFF1A".concat(name || outLineName);
      } else if (type === 2) {
        // 章服务
        return "\u672C\u7AE0\u670D\u52A1";
      } else if (type === 3) {
        // 关练习
        return "\u672C\u5173\u7EC3\u4E60\uFF1A".concat(name || outLineName);
      } else if (type === 1) {
        // 关服务
        return "\u672C\u5173\u670D\u52A1";
      }

      if (!outLineSeqName || !outLineName) {
        return '';
      }

      return "".concat(outLineSeqName, "\uFF1A").concat(outLineName);
    },
    boxInfo: function boxInfo() {
      var _this = this;

      if (!Array.isArray(this.boxes) || !this.boxId) {
        return {};
      }

      return this.boxes.find(function (item) {
        return item.boxId === +_this.boxId;
      }) || {};
    }
  },
  watch: {
    boxId: function boxId() {
      var _states,
          _states2,
          _this2 = this;

      var states = cache.getBoxState(this.edu, this.subjectCode, this.boxId); // 两个选中算一个全部；两个都不选算全部，选一个算中

      if (states && ((_states = states) !== null && _states !== void 0 && _states.every(function (state) {
        return state.select;
      }) || (_states2 = states) !== null && _states2 !== void 0 && _states2.every(function (state) {
        return !state.select;
      }))) {
        states = undefined;
      }

      this.states = states || JSON.parse(JSON.stringify(SUBMIT_STATUS));
      var box = this.boxes.find(function (box) {
        return box.boxId === +_this2.boxId;
      });
      this.boxName = (box === null || box === void 0 ? void 0 : box.name) || '';
    }
  },
  methods: {
    changeStatus: function changeStatus(item) {
      this.states = this.states.map(function (state) {
        state.select = state.status === item.status;
        return state;
      });
      cache.changeState(this.edu, this.subjectCode, this.boxId, this.states);
    },
    changeOutline: function changeOutline(selectNode, isUpdateCache) {
      this.selectNode = selectNode;

      if (isUpdateCache) {
        cache.changeOutline(this.edu, this.subjectCode, this.boxId, selectNode);
      }
    },
    changeBox: function changeBox() {
      this.showBookModal = false;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.$emit.apply(this, ['handleChangeBox'].concat(args));
    }
  }
};