import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

/*
 * @Author       : wanchao4@xdf.cn
 * @Date         : 2021-11-04 15:59:36
 * @LastEditors  : zhangying@xdf.cn
 * @LastEditTime : 2021-11-29 10:26:22
 * @Description  : 调用服务项-路由跳转
 */
export default function callService(url, context) {
  if (url.includes('/answer/?') || url.includes('/answer?')) {
    window.location.href = url;
  } else {
    context.$router.push({
      path: '/service',
      query: {
        url: url // t: Date.now()

      }
    });
  }
}