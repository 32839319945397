import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'XdfProgress',
  props: {
    percentage: {
      type: Number,
      default: 0,
      validator: function validator(val) {
        return val >= 0 && val <= 100;
      }
    },
    showPercentageText: {
      type: Boolean,
      default: false
    },
    borderRadiusLeftNo: {
      type: Boolean,
      default: false
    },
    percentageHeight: {
      type: Number,
      default: 0
    },
    outerBgColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.15)'
    },
    barBgColor: {
      type: String,
      default: ''
    },
    outerWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percentageLocal: function percentageLocal() {
      if (isNaN(this.percentage)) {
        return 0;
      }

      return +this.percentage > 100 ? 100 : +this.percentage;
    },
    percentageText: function percentageText() {
      if (!this.percentageLocal) {
        return '';
      }

      return this.percentageLocal + '%';
    },
    outerStyle: function outerStyle() {
      if (this.outerWidth) {
        return {
          width: this.outerWidth + 'px',
          backgroundColor: this.outerBgColor
        };
      }

      return {
        backgroundColor: this.outerBgColor
      };
    },
    barStyle: function barStyle() {
      return {
        width: this.percentageLocal + '%',
        height: this.percentageHeight + 'px',
        backgroundColor: this.percentageLocal ? this.barBgColor : 'transparent'
      };
    }
  }
};