//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ErrorTips',
  data: function data() {
    return {
      step: 1
    };
  },
  methods: {
    changeStep: function changeStep() {
      if (this.step === 1) {
        this.step = 2;
      } else {
        this.$emit('closeTips');
      }
    }
  }
};