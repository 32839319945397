import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import IframeWeb from '@/views/iframe-server';
import { mapGetters } from 'vuex';
export default {
  name: 'HelpCenter',
  components: {
    IframeWeb: IframeWeb
  },
  data: function data() {
    return {
      defaultUrl: "".concat(process.env.VUE_APP_BASE_API, "/webviewpad/helpCenter"),
      iframeUrl: ''
    };
  },
  computed: _objectSpread({}, mapGetters(['serviceTitle'])),
  created: function created() {
    this.iframeUrl = this.$route.query.url || this.defaultUrl;
  }
};