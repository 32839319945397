import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

/*
 * @Author       : wanchao4@xdf.cn
 * @Date         : 2022-03-03 18:08:16
 * @LastEditors  : wanchao4@xdf.cn
 * @LastEditTime : 2022-03-03 18:15:13
 * @Description  : safari回退强制刷新
 */
function forceReload(url) {
  var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;

  if (browserRule.test(navigator.userAgent)) {
    var reload = function reload(event) {
      if (event.persisted && url.includes('/service')) {
        window.location.reload();
      }
    };

    window.removeEventListener('pageshow', reload);
    window.addEventListener('pageshow', reload);
  }
}

export { forceReload };