/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2021-11-22 14:15:57
 * @LastEditors  : zhangying@xdf.cn
 * @LastEditTime : 2021-11-22 14:15:57
 * @Description  : 描述信息
 */
var timer = null;
export default {
  // 初始化指令
  bind: function bind(el, binding, vnode) {
    function mouseHandler(status) {
      binding.value(status);
    } // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听


    el.__vueClickOutside__ = mouseHandler;
    el.addEventListener('mouseover', function (e) {
      clearTimeout(timer);
      mouseHandler(true);
    });
    el.addEventListener('mouseout', function (e) {
      timer = setTimeout(function () {
        mouseHandler(false);
      }, 300);
    });
  },
  update: function update() {},
  unbind: function unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
};