/*
 * @Author       : zhaoranyue@xdf.cn
 * @Date         : 2021-11-08 17:21:58
 * @LastEditors  : zhaoranyue@xdf.cn
 * @LastEditTime : 2021-11-22 10:12:30
 * @Description  : 描述信息
 */
import { request } from './request/index'; // 查询学生当日是否有新的解锁任务

export function queryNewUnlockTasks(params) {
  return request.post('/repeat/taskCard/queryNewUnlockTasks', params);
} // 记忆验证卡片信息

export function queryMemoryCardInfo(params) {
  return request.post('/repeat/taskCard/queryMemoryCardInfo', params);
} // 今日复习计划

export function queryReviewPlan(params) {
  return request.post('/repeat/taskCard/queryReviewPlan', params);
} // 今日待做任务卡片列表

export function queryTaskInfo(params) {
  return request.post('/repeat/taskCard/queryTaskInfo', params);
} // 任务卡片作答

export function recordAnswer(params) {
  return request.post('/repeat/answerRecord/answer', params);
} // 根据学生学科查询复习规则

export function queryConfig(params) {
  return request.post('/repeat/cardPushConfig/query/byStudent', params);
} // 设置复习规则

export function setConfig(params) {
  return request.post('/repeat/cardPushConfig/set', params);
}