import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BoxList',
  props: {
    boxList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imgList: []
    };
  },
  computed: {
    imgClass: function imgClass() {
      return this.imgList.length > 1 ? 'box-list__img__item2' : 'box-list__img__item1';
    }
  },
  watch: {
    boxList: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.imgList = this.boxList.length > 4 ? this.boxList.slice(0, 4) : this.boxList;
      }
    }
  }
};