import { render, staticRenderFns } from "./mask-loading.vue?vue&type=template&id=9819248c&scoped=true&"
import script from "./mask-loading.vue?vue&type=script&lang=js&"
export * from "./mask-loading.vue?vue&type=script&lang=js&"
import style0 from "./mask-loading.vue?vue&type=style&index=0&id=9819248c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9819248c",
  null
  
)

export default component.exports