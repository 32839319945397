/*
 * @Author       : zhaoranyue@xdf.cn
 * @Date         : 2021-10-21 09:48:30
 * @LastEditors  : zhaoranyue@xdf.cn
 * @LastEditTime : 2022-03-08 11:07:17
 * @Description  : 描述信息
 */
export var DEFAULT_SERVICES = [// {
//   type: 16,
//   title: '错题复习',
//   icon: 'review_error_img.png'
// },
{
  type: 17,
  title: '笔记复习',
  icon: 'review_note_img.png'
}];
export var PROGRESS_TIP_LIST = ['总卡片：复习分册中含有的记忆验证卡片总数', '已掌握：完成全部复习轮次及设置为"已掌握"的记忆验证卡片总数'];
export var TOTAL_NUM_TIP_LIST = ['今日待复习 = 新卡 + 复习卡', '新卡：初次完成学习分册某一知识探索视频学习后，将推荐与学习内容对应的记忆验证卡片', '复习卡：新卡完成学习后，继续按照艾宾浩斯遗忘曲线复习间隔推荐的记忆验证卡片'];
export var BATCH_CARD_RADIO_LIST = [{
  label: '全部',
  id: -1
}, {
  label: '只看复习卡',
  id: 2
}];