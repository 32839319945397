//
//
//
//
//
//
//
//
//
import CaptchaLogin from './captcha-login.vue';
import PasswordLogin from './password-login.vue';
export default {
  components: {
    CaptchaLogin: CaptchaLogin,
    PasswordLogin: PasswordLogin
  },
  data: function data() {
    return {
      loginWay: 1
    };
  },
  methods: {
    // 切换登录方式，1为账号验证码、2为账号密码
    cutLoginWay: function cutLoginWay(val) {
      this.loginWay = val;
    },
    finishLogin: function finishLogin(userInfo) {
      this.$emit('finishLogin', userInfo);
    }
  }
};