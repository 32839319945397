//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    radio: {
      type: String,
      default: ''
    },
    callBack: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    handleAgreement: function handleAgreement() {
      var val = '';

      if (this.radio !== '1') {
        val = '1';
      }

      this.callBack(val);
    },
    clickAgreement: function clickAgreement() {
      window.open("".concat(process.env.VUE_APP_BASE_PATH, "agreement"));
    },
    clickPrivacy: function clickPrivacy() {
      window.open("".concat(process.env.VUE_APP_BASE_PATH, "privacy"));
    }
  }
};