//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DarkBg from '@/components/dark-bg.vue';
import OutlineCache from '@/utils/OutlineCache.js';
var cache = OutlineCache.getInstance();
export default {
  name: 'HomeSelfStudy',
  components: {
    DarkBg: DarkBg
  },
  props: {
    subject: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    studyList: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    subjectName: function subjectName() {
      return this.subject ? this.subject.zhName : '';
    }
  },
  methods: {
    bindNew: function bindNew() {
      this.$emit('onBind');
    },
    study: function study(item) {
      var edu = item.edu,
          subjectId = item.subjectId,
          boxId = item.boxId;
      cache.switchSubject(edu, subjectId);
      cache.switchBox(edu, subjectId, boxId);
      this.$router.push({
        path: '/study'
      });
    },
    review: function review(item) {
      // TODO
      this.$router.push({
        path: '/review'
      });
    }
  }
};