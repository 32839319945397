import _toConsumableArray from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ProgressSingleGuan from '@/views/study/components/progress/progress-single-guan.vue';
export default {
  name: 'ProgressGuanCollapse',
  components: {
    ProgressSingleGuan: ProgressSingleGuan
  },
  props: {
    node: {
      type: Object,
      default: function _default() {}
    },
    expandNodes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      activeName: []
    };
  },
  computed: {
    open: function open() {
      return this.activeName.includes(this.node.outLineId);
    }
  },
  created: function created() {
    this.activeName = _toConsumableArray(this.expandNodes);
  },
  methods: {
    openCloseColl: function openCloseColl() {
      // 更新展开的节点信息
      this.$emit('expandCollNode', this.node.outLineId);
    },
    nodeClick: function nodeClick(node) {
      this.$emit('nodeClick', node);
    }
  }
};