import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import DownloadBook from '@/views/user-center/dialog/download.vue';
import { playOrPauseVideo } from '@/utils/guide-video';
export default {
  name: 'DrawerHeader',
  components: {
    DownloadBook: DownloadBook
  },
  props: {
    pdfurl: {
      type: String,
      default: ''
    },
    zipFileName: {
      type: String,
      default: '下载'
    },
    preciousType: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      zipData: null,
      tabList: [{
        id: 1,
        name: '学习分册'
      }, {
        id: 2,
        name: '练习分册'
      }, {
        id: 3,
        name: '复习分册'
      }, {
        id: 4,
        name: '智能学习系统'
      }],
      activyId: 1,
      downloadVisible: false
    };
  },
  computed: _objectSpread({}, mapGetters({
    experienceInfos: 'experienceInfos'
  })),
  watch: {
    preciousType: function preciousType(val) {
      this.activyId = val;
    },
    downloadVisible: function downloadVisible(val) {
      playOrPauseVideo(val ? 'videoPause' : 'videoPlay');
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.activyId = this.experienceInfos.preciousType || 1;
    this.$bus.$on('toNextType', function () {
      _this.handlerChangeType(_this.activyId + 1);
    });
  },
  methods: {
    handlerChangeType: function handlerChangeType(val) {
      this.activyId = val;
      this.$emit('update:preciousType', val);
    },
    downLoadPdf: function downLoadPdf() {
      this.downloadVisible = true;
    }
  }
};