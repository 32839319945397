//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'UserInfo',
  props: {
    title: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    configList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    clickMenu: function clickMenu(item) {
      this.$emit('clickMenu', item);
    }
  }
};