//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MaskLoading',
  data: function data() {
    return {
      isShowBack: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      var time = 15;
      var timer = setInterval(function () {
        time--;

        if (time === 0) {
          clearInterval(timer);
          _this.isShowBack = true;
        }
      }, 1000);
      this.$once('hook:beforeDestroy', function () {
        clearInterval(timer);
      });
    },
    goBack: function goBack() {
      this.$router.back();
    }
  }
};