var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-scrollbar',{staticClass:"box-tree"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEmpty),expression:"!showEmpty"}],staticClass:"box-tree-inner"},[_c('box-guide-outline',{attrs:{"select-node":_vm.selectNode,"box-id":_vm.boxId},on:{"nodeClick":_vm.nodeClick}}),_vm._l((_vm.box),function(chapter){return _c('div',{key:chapter.outLineId,ref:("outline_chapter_" + (chapter.outLineId)),refInFor:true,class:{ 'is-hide': chapter.hideNode }},[_c('box-main-outline-zhang',{attrs:{"chapter":chapter,"select-node":_vm.selectNode},on:{"chapterClick":_vm.chapterClick}}),_vm._l((chapter.children),function(guan){return _c('div',{key:guan.outLineId},[((guan.children&&guan.children.length)||(guan.virtualNode&&guan.virtualNode.length))?_c('box-main-outline-collapse',{attrs:{"node":guan,"select-node":_vm.selectNode,"states":_vm.states,"expand-nodes":_vm.expandNodes},on:{"nodeClick":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.nodeClick.apply(void 0, args.concat( [chapter] ));
},"expandCollNode":_vm.expandCollNode}}):_c('box-main-outline-card',{attrs:{"virtual-node":guan,"select-node":_vm.selectNode,"states":_vm.states},on:{"nodeClick":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.nodeClick.apply(void 0, args.concat( [chapter] ));
}}})],1)}),_vm._l((chapter.virtualNode),function(virtual){return _c('box-main-outline-card',{key:virtual.type,attrs:{"virtual-node":virtual,"select-node":_vm.selectNode,"states":_vm.states,"section-name":chapter.outLineName},on:{"nodeClick":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.nodeClick.apply(void 0, args.concat( [chapter] ));
}}})})],2)})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmpty),expression:"showEmpty"}],staticClass:"box-tree-empty"},[_c('img',{staticClass:"box-tree-empty-img",attrs:{"src":_vm.emptyImg}}),_vm._v(" 没有符合条件的结果 ")])])}
var staticRenderFns = []

export { render, staticRenderFns }