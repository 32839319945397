import "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/core-js/modules/es.array.iterator.js";
import "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/core-js/modules/es.promise.js";
import "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/core-js/modules/es.object.assign.js";
import "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import Router from 'vue-router'; // reset style

import 'normalize.css/normalize.css'; // element

import './element-dep';
import '@/styles/element-variables.scss';
import '@/styles/element-replenish.scss'; // root style

import '@/styles/index.scss';
import App from './App';
import store from './store'; // TODO-w 如果需要store
// import store from './store'

import router from './router';
import showModal from '@/components/dialog/index.js';
import eventBus from '@/utils/eventBus';
import md5 from 'js-md5';
import { initSentry } from '@/utils/sentry';
import { resource } from '@/utils/tools';
import directive from '@/directive/index.js';
Vue.use(Router);
Vue.use(directive);
Vue.config.productionTip = false;
Vue.prototype.$resource = resource; // TODO-w 如果需要showModal

Vue.prototype.$showModal = showModal; // 添加事件总线

Vue.prototype.$bus = eventBus; // md5

Vue.prototype.$md5 = md5;

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_TARGET === 'online') {
  // 初始化sentry
  initSentry();
}

new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});