import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'NameDialog',
  props: {
    userInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      name: ''
    };
  },
  mounted: function mounted() {
    this.name = this.userInfo.name;
  },
  methods: {
    saveName: function saveName() {
      if (!this.name) {
        return;
      }

      this.$attrs.close({
        name: this.name.replace(/( )/g, '')
      });
    },
    clearName: function clearName() {
      this.name = '';
    }
  }
};