import _objectSpread from "/neworiental/jenkins/workspace/seal-remixed-troy-student-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { getProductDesc } from '@/api/modules/select';
export default {
  name: 'CourseOutline',
  data: function data() {
    return {
      isShowOutline: false,
      imgList: []
    };
  },
  computed: _objectSpread({}, mapGetters({
    experienceBook: 'experienceBook'
  })),
  created: function created() {
    var _this = this;

    getProductDesc({
      libraryId: this.experienceBook.libraryId
    }).then(function (res) {
      _this.imgList = res.data.image;
    });
  }
};