import "core-js/modules/es.array.includes.js";

/*
 * @Author: menglingze
 * @Date: 2021-12-06 18:10:21
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-12-08 17:54:10
 */
// 关于滑块config
export function captchaSlideConfig(area) {
  var VUE_APP_TARGET = process.env.VUE_APP_TARGET;
  var IS_ONLINE = ['online', 'pre'].includes(VUE_APP_TARGET);
  return {
    app_id: 'xdf-edu',
    isTest: !IS_ONLINE,
    area: area,
    next_width: '280px'
  };
}