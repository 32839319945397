//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DrawerHeader from './drawer-header.vue';
export default {
  name: 'ExperienceGuideDialog',
  components: {
    DrawerHeader: DrawerHeader
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      guideVisible: true
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    }
  }
};