import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import progressBar from '@/components/progress-bar.vue';
import TipDialog from '@/views/review/components/tip-dialog.vue';
import { PROGRESS_TIP_LIST } from '@/views/review/constant.js';
export default {
  name: 'TotalProgress',
  components: {
    progressBar: progressBar
  },
  props: {
    progressData: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    percent: function percent() {
      return this.progressData.percent ? this.progressData.percent.toFixed(1) : '0.0';
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$showModal(TipDialog, {
        tipList: PROGRESS_TIP_LIST
      }, {
        title: '说明',
        noFooter: true,
        width: '400px',
        showClose: false,
        closeOnClickModal: true,
        customClass: 'tip-dialog'
      });
    }
  }
};