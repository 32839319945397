/*
 * @Author       : zhucaiyun1@xdf.cn
 * @Date         : 2022-02-08 15:38:00
 * @LastEditors  : zhucaiyun1@xdf.cn
 * @LastEditTime : 2022-02-08 15:38:53
 * @Description  : 描述信息
 */
import { request } from '../request'; // 打印记录列表 https://yapi.staff.xdf.cn/project/1407/interface/api/80485

export var printNum = function printNum(params) {
  return request.post("/resource/download/incr_filter_e", params);
};