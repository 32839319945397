import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.slice.js";

/*
 * @Author       : zhangying@xdf.cn
 * @Date         : 2021-11-24 10:58:09
 * @LastEditors  : zhangying@xdf.cn
 * @LastEditTime : 2021-11-24 10:58:09
 * @Description  : 描述信息
 */
// 计算字符长度 1中文:2 个字符;2英文:1 个字符;3中文符号:2 个字符;4英文半角符号:1 个字符; 5英文全角符号:2 个字符;
export function getByteLenAndSlice(val, leng) {
  var len = 0;
  var sliceStr = '';
  var isSlice = false;

  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i); // eslint-disable-next-line no-control-regex

    if (a.match(/[^\x00-\xff]/ig) != null) {
      len += 2;
    } else {
      len += 1;
    }

    if (len > leng && !isSlice) {
      sliceStr = val.slice(0, i);
      isSlice = true;
    }
  }

  return {
    len: len,
    sliceStr: sliceStr
  };
}