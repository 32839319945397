//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    node: {
      type: Object,
      default: function _default(_) {
        return {};
      }
    }
  },
  computed: {
    isLeafNode: function isLeafNode() {
      var _this$node$children, _this$node$virtualNod;

      return !((_this$node$children = this.node.children) !== null && _this$node$children !== void 0 && _this$node$children.length) && !((_this$node$virtualNod = this.node.virtualNode) !== null && _this$node$virtualNod !== void 0 && _this$node$virtualNod.length);
    }
  }
};